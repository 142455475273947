body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-left{
  text-align: left !;
}
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the layout to take up the full viewport height */
  width: 100vw;
}

.top {
  flex: 0; /* Header and subheader will not scroll */
  position: sticky;
  top: 0 !important;
  z-index: 100; /* Set a z-index to make the header and subheader appear above the content */
}

.content {
  flex: 1; /* The content area will take up the remaining space and scroll if needed */
  background-color: var(--bg);
}